import React from "react"
import { Container, NewsArchive } from "../components"
import Seo from "../components/seo"

const NewsPage = () => {
  return (
    <Container>
      <Seo
        title="お知らせ・スタッフブログ"
        description="恵比寿美容皮膚科のお得なキャンペーン情報のお知らせやスタッフブログをご覧いただけます。お得な情報もたくさんありますので、美容にご興味のある方はぜひご覧ください。"
      />
      <NewsArchive />
    </Container>
  )
}

export default NewsPage
